import axios from "axios";
import { API_ORDERS_URL } from "../utils/constants";
import { handleServiceError } from "../utils/helpers";

export const createOrder = async (payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(API_ORDERS_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrder = async (orderId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_ORDERS_URL}/${orderId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const deleteOrder = async (orderId: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.delete(`${API_ORDERS_URL}/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const createOrderClientPerson = async (orderId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_ORDERS_URL}/${orderId}/client/person`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderClientPerson = async (orderId: string, personId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_ORDERS_URL}/${orderId}/client/person/${personId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const createOrderAddress = async (orderId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_ORDERS_URL}/${orderId}/address`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderAddress = async (orderId: string, personId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_ORDERS_URL}/${orderId}/address/${personId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderStatusToValidatingOrder = async (orderId: string, sendNotification: boolean) => {
    const token = localStorage.getItem("access_token");
    try {

        const url = `${API_ORDERS_URL}/${orderId}/status/validating-order`;
        const sendNotificationParam = `sendNotification=${sendNotification}`;

        const response = await axios.put(`${url}?${sendNotificationParam}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderStatusToInProduction = async (orderId: string, sendNotification: boolean) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_ORDERS_URL}/${orderId}/status/in-production`;
        const sendNotificationParam = `sendNotification=${sendNotification}`;

        const response = await axios.put(`${url}?${sendNotificationParam}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderStatusToReceivedInStore = async (orderId: string, sendNotification: boolean) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_ORDERS_URL}/${orderId}/status/received-in-store`;
        const sendNotificationParam = `sendNotification=${sendNotification}`;

        const response = await axios.put(`${url}?${sendNotificationParam}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderStatusToReadyToSchedule = async (orderId: string, sendNotification: boolean) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_ORDERS_URL}/${orderId}/status/ready-to-schedule`;
        const sendNotificationParam = `sendNotification=${sendNotification}`;

        const response = await axios.put(`${url}?${sendNotificationParam}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderStatusToScheduled = async (orderId: string, payload: any, sendNotification: boolean) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_ORDERS_URL}/${orderId}/status/scheduled`;
        const sendNotificationParam = `sendNotification=${sendNotification}`;

        const response = await axios.put(`${url}?${sendNotificationParam}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderStatusToProjectFinished = async (orderId: string, payload: FormData, sendNotification: boolean) => {
    const token = localStorage.getItem("access_token");
    try {

        const url = `${API_ORDERS_URL}/${orderId}/status/project-finished`;
        const sendNotificationParam = `sendNotification=${sendNotification}`;

        const response = await axios.put(`${url}?${sendNotificationParam}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateOrderStatusToProjectUnfinished = async (orderId: string, payload: FormData, sendNotification: boolean) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_ORDERS_URL}/${orderId}/status/project-unfinished`;
        const sendNotificationParam = `sendNotification=${sendNotification}`;

        const response = await axios.put(`${url}?${sendNotificationParam}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const assignClientToOrder = async (id: string, clientId: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_ORDERS_URL}/${id}/assign-client/${clientId}`;
        const response = await axios.put(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const getOrderGuaranteesByOrderId = async (
    size: number = 10,
    page: number = 0,
    sort: string = "createdAt,asc",
    orderId: string,
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(`${API_ORDERS_URL}/${orderId}/guarantees?page=${page}&size=${size}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const getOrdersUnfinished = async (
    size: number = 10,
    page: number = 0,
    sort: string = "createdAt,asc",
    searchByFullName?: string,
) => {
    const token = localStorage.getItem("access_token");
    try {
        const searchParam = searchByFullName ? `&searchByFullName=${searchByFullName}` : "";
        const response = await axios.get(`${API_ORDERS_URL}/status/project-unfinished?page=${page}&size=${size}&sort=${sort}${searchParam}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const createOrderFromProspect = async (prospectId: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_ORDERS_URL}/prospect/${prospectId}`;
        const response = await axios.post(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const createOrderPayment = async (orderId: string, payload: FormData) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_ORDERS_URL}/${orderId}/payments`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const getOrderPayments = async (orderId: string, types?: string[]) => {
    const token = localStorage.getItem("access_token");
    try {
        const searchTypes = types ? `?types=${types.join(",")}` : "";
        const response = await axios.get(`${API_ORDERS_URL}/${orderId}/payments${searchTypes}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const createOrderExternalUpdate = async (orderId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_ORDERS_URL}/${orderId}/external-updates`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const getOrderExternalUpdates = async (orderId: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(`${API_ORDERS_URL}/${orderId}/external-updates`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};
