import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, FormField, FormGroup, Grid, GridColumn, Header, Segment, Select, TextArea } from "semantic-ui-react";
import { createProspect, updateProspect } from "../../../services/prospects.service";
import { getUsers } from "../../../services/users.service";
import { USER_ROLE_ADVISOR } from "../../../utils/constants";
import { getProducts } from "../../../services/products.service";
import { setHours, setMinutes } from "date-fns";
import Prospect from "../../../types/entities/Prospect";
import { toast } from "react-toastify";
import { useAuth } from "../../../hooks/AuthProvider";
import DropdownOption from "../../../types/models/DropdownOption";
import { PROSPECT_ORIGIN_OPTIONS } from "../../../utils/dropdownOptions";

interface Props {
    prospectId: string | undefined;
    prospect: Prospect | null;
    setProspect: (prospect: Prospect | null) => void;
}

interface RequestData {
    origin?: string;
    advisorId?: string;
    productId?: string;
    appointment?: Date | null;
    notes?: string;
}

const ProspectAppointmentForm = ({ prospectId, prospect, setProspect }: Props) => {

    const auth = useAuth();
    const userLogged = auth.userData ?? null;
    const userIsAdvisor = userLogged?.role === USER_ROLE_ADVISOR;

    const [ loading, setLoading] = useState(false);

    const [advisors, setAdvisors] = useState<DropdownOption[]>([]);
    const [products, setProducts] = useState<DropdownOption[]>([]);

    const [ entityData, setEntityData ] = useState<Prospect | null>(null);
    const [ requestData, setRequestData ] = useState<RequestData | undefined>(undefined);

    useEffect(() => {
        setEntityData(prospect);
        setRequestData({
            origin: entityData?.origin || "",
            advisorId: entityData?.advisor?.id || "",
            productId: entityData?.product?.id || "",
            appointment: entityData?.appointment ? new Date(entityData.appointment) : null,
            notes: entityData?.notes || "",
        });
    }, [ prospect, entityData ]);

    const handleChange = (e: any, { name, value }: any) => {
        console.log(name, value);
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        } as RequestData);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        try {

            if (!prospectId) {
                throw new Error("El prospecto no puede ser undefined.");
            }

            if (entityData?.id) {
                const response = await updateProspect(prospectId, requestData);

                if (response?.status === 200) {
                    setProspect(response.data);
                    toast.success("El prospecto ha sido actualizado exitosamente.");
                } else {
                    throw new Error("El prospecto no ha sido actualizado exitosamente, favor de contactar al area de TI");
                }
            } else {
                const response = await createProspect(requestData);

                if (response?.status === 201) {
                    setProspect(response.data);
                    toast.success("El prospecto ha sido creado exitosamente.");
                } else {
                    throw new Error("El prospecto no ha sido creado exitosamente, favor de contactar al area de TI");
                }
            }
            
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchAdvisors = useCallback(async () => {
        try {
            if (userIsAdvisor) {
                const advisorsList = [{
                    key: userLogged?.id,
                    value: userLogged?.id,
                    text: userLogged?.person?.fullName ?? "Sin nombre",
                }];
                setAdvisors(advisorsList);
            } else {
                const response = await getUsers(100, 0, USER_ROLE_ADVISOR);
                if (!response?.data.content) {
                    return;
                }
                const advisorsList = response?.data.content.map(
                    (advisor: any) => {
                        return {
                            key: advisor.id,
                            value: advisor.id,
                            text: advisor.person?.fullName ?? "Sin nombre",
                        };
                    }
                );
                setAdvisors(advisorsList);
            }
        } catch (error) {
            toast.error("Error al obtener la lista de asesores");
        }
    }, [userIsAdvisor, userLogged, setAdvisors]);

    const fetchProducts = async () => {
        try {
            const response = await getProducts(100, 0);
            const productsList = response?.data.content.map(
                (product: any) => {
                    return {
                        key: product["id"],
                        value: product["id"],
                        text: `${product["sku"]} - ${product["name"]}`,
                    };
                }
            );
            setProducts(productsList);
        } catch (error) {
            toast.error("Error al obtener la lista de productos");
        }
    };

    useEffect(() => {
        fetchAdvisors();
        fetchProducts();
    }, [fetchAdvisors, userLogged]);

    return (
        <Form onSubmit={handleSubmit}>
            <Grid>
                <GridColumn>
                    <Segment attached='top' color="grey" style={{ marginTop: 15 }}>
                        <Grid>
                            <GridColumn style={{ paddingTop: 5, paddingBottom: 5 }} verticalAlign="middle">
                                <Header as='h4' floated="left" style={{ marginTop: 8, marginBottom: 7 }}>Datos del prospecto</Header>
                                { entityData?.id ? (
                                    <Button floated="right" color="yellow" type="submit" style={{ margin: 0 }} disabled={loading}>Actualizar</Button>
                                ): (
                                    <Button floated="right" primary type="submit" style={{ margin: 0 }} disabled={loading}>Guardar</Button>
                                )} 
                            </GridColumn>
                        </Grid>
                    </Segment>
                    <Segment attached='bottom' loading={loading}>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-origin">Canal de origen</label>}
                                options={PROSPECT_ORIGIN_OPTIONS}
                                placeholder="Selecciona una opcion"
                                name="origin"
                                value={requestData?.origin || ""}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-origin"}}
                                clearable
                            />
                            <FormField
                                disabled={userIsAdvisor}
                                control={Select}
                                label={<label htmlFor="form-select-control-advisor">Asesor</label>}
                                options={advisors}
                                placeholder="Selecciona una opcion"
                                name="advisorId"
                                value={requestData?.advisorId || ""}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-advisor"}}
                                clearable
                            />
                        </FormGroup>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-advisor">Producto de interes</label>}
                                options={products}
                                placeholder="Selecciona una opcion"
                                name="productId"
                                value={requestData?.productId || ""}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-advisor"}}
                                clearable
                            />
                            <FormField required>
                                <label>Fecha y hora de visita</label>
                                <DatePicker
                                    showTimeSelect
                                    isClearable
                                    minDate={new Date()}
                                    minTime={setHours(setMinutes(new Date(), 59),7)}
                                    maxTime={setHours(setMinutes(new Date(), 59),21)}
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    selected={requestData?.appointment}
                                    placeholderText="Fecha de visita"
                                    onChange={(date: Date | null, e) =>handleChange(e, { name: "appointment", value: date })}
                                    wrapperClassName="date-picker-custom-parent"
                                    className="date-picker-custom"
                                    name="appointment"
                                />
                            </FormField>
                        </FormGroup>
                        <FormGroup widths='equal'>
                            <FormField
                                control={TextArea}
                                label="Notas adicionales"
                                placeholder="Añade notas adicionales para el prospecto..."
                                name="notes"
                                value={requestData?.notes}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Segment>
                </GridColumn>
            </Grid>
        </Form>
    );
};

export default ProspectAppointmentForm;
