import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';
import Order from '../../../types/entities/Order';
import { updateOrderStatusToInProduction } from '../../../services/orders.service';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../hooks/AppProvider';
import { useAuth } from '../../../hooks/AuthProvider';
import { USER_ROLE_ADMINISTRATOR } from '../../../utils/constants';

interface Props {
    id: string | undefined;
    setOrder: (order: Order) => void;
}

const UpdateStatusToProductionModal = (props: Props) => {

    const { updateParentState } = useAppContext();
    const { userData } = useAuth();
    const isSuperAdmin = userData?.role === USER_ROLE_ADMINISTRATOR;

    const [ openModal, setOpenModal ] = useState(false);
    const [ confirmChecked, setConfirmChecked ] = useState(false);
    const [ sendNotification, setSendNotification ] = useState(true);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    const handleInProduction = async (event: SyntheticEvent, orderId: string | undefined, sendNotification: boolean) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {

            if (orderId === undefined) {
                throw new Error("El id del pedido no puede ser undefined.");
            }

            const response = await updateOrderStatusToInProduction(orderId, sendNotification);
            if (response?.status === 200) {
                props.setOrder(response?.data);
                toast.success("El pedido ha sido movido a produccion exitosamente.");
                setOpenModal(false);
            } else {
                throw new Error("Ha ocurrido un error al mover el pedido a produccion.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    return (
        <>
            
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="green" floated='right' icon onClick={ (event) => handleOpenModal(event) } >
                                Mover pedido a "En produccion"
                            </Button>
                        }
                        content='Mover pedido a "En producción"'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="check square" content='Mover pedido a "En produccion"' />
                <ModalContent>
                    <p>
                        Recuerda que una vez que modifiques el estatus ya no se podra regresar al estatus
                        anterior y se enviaran las notificaciones correspondientes al cliente.
                    </p>

                    <div className="ui form">
                        <FormField>
                            <Checkbox 
                                toggle
                                disabled={!isSuperAdmin}
                                checked={sendNotification}
                                label='Enviar notificación de whatsapp'
                                onChange={ (event, data) => setSendNotification(sendNotification ? false : true) }
                            />
                        </FormField>
                        <FormField>
                            <Checkbox
                                toggle
                                label="Confirmo que quiero actualizar el estatus del pedido."
                                checked={confirmChecked}
                                onChange={ (event, data) => setConfirmChecked(confirmChecked ? false : true) }
                            />
                        </FormField>
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => handleInProduction(event, props.id, sendNotification) } disabled={!confirmChecked}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default UpdateStatusToProductionModal;
