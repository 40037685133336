import React, { useEffect, useState } from "react";
import { Icon, Step, StepContent, StepTitle } from "semantic-ui-react";

interface Props {
	status: string | undefined;
}

const OrderStatusSteps = (props: Props) => {

	const defaultState = {
		disabled: true, active: false, completed: false
	};

	const [validatingOrder, setValidatingOrder] = useState(defaultState);
	const [inProduction, setInProduction] = useState(defaultState);
	const [receivedInStore, setReceivedInStore] = useState(defaultState);
	const [readyToSchedule, setReadyToSchedule] = useState(defaultState);
	const [scheduled, setScheduled] = useState(defaultState);
	const [projectCompleted, setProjectCompleted] = useState(defaultState);
	const [projectUnfinished, setProjectUnfinished] = useState(defaultState);

	useEffect(() => {
		if (props.status !== undefined) {
			switch (props.status) {
				case "VALIDANDO_PEDIDO":
					setValidatingOrder({ disabled: false, active: true, completed: false });
					break;
				case "EN_PRODUCCION":
					setValidatingOrder({ disabled: false, active: false, completed: true });
					setInProduction({ disabled: false, active: true, completed: false });
					break;
				case "RECIBIDO_EN_ALMACEN":
					setValidatingOrder({ disabled: false, active: false, completed: true });
					setInProduction({ disabled: false, active: false, completed: true });
					setReceivedInStore({ disabled: false, active: true, completed: false });
					break;
				case "LISTO_PARA_AGENDAR":
					setValidatingOrder({ disabled: false, active: false, completed: true });
					setInProduction({ disabled: false, active: false, completed: true });
					setReceivedInStore({ disabled: false, active: false, completed: true });
					setReadyToSchedule({ disabled: false, active: true, completed: false });
					setScheduled({ disabled: true, active: false, completed: false });
					break;
				case "AGENDADO":
					setValidatingOrder({ disabled: false, active: false, completed: true });
					setInProduction({ disabled: false, active: false, completed: true });
					setReceivedInStore({ disabled: false, active: false, completed: true });
					setReadyToSchedule({ disabled: false, active: false, completed: true });
					setScheduled({ disabled: false, active: true, completed: false });
					break;
				case "PROYECTO_TERMINADO":
					setValidatingOrder({ disabled: false, active: false, completed: true });
					setInProduction({ disabled: false, active: false, completed: true });
					setReceivedInStore({ disabled: false, active: false, completed: true });
					setReadyToSchedule({ disabled: false, active: false, completed: true });
					setScheduled({ disabled: false, active: false, completed: true });
					setProjectCompleted({ disabled: false, active: true, completed: false });
					break;
				case "PROYECTO_INCONCLUSO":
					setValidatingOrder({ disabled: false, active: false, completed: true });
					setInProduction({ disabled: false, active: false, completed: true });
					setReceivedInStore({ disabled: false, active: false, completed: true });
					setReadyToSchedule({ disabled: false, active: false, completed: true });
					setScheduled({ disabled: false, active: false, completed: true });
					setProjectUnfinished({ disabled: false, active: true, completed: false });
					break;
			}
		}
	}, [props.status]);

    return (
		<Step.Group size="tiny" widths={6}>

			<Step disabled={validatingOrder.disabled} active={validatingOrder.active} completed={validatingOrder.completed}>
				<Icon name='upload' />
				<StepContent>
					<StepTitle>Validando pedido</StepTitle>
				</StepContent>
			</Step>

			<Step disabled={inProduction.disabled} active={inProduction.active} completed={inProduction.completed}>
				<Icon name='wrench' />
				<StepContent>
					<StepTitle>En producción</StepTitle>
				</StepContent>
			</Step>

			<Step disabled={receivedInStore.disabled} active={receivedInStore.active} completed={receivedInStore.completed}>
				<Icon name='warehouse' />
				<StepContent>
					<StepTitle>Recibido en almacen</StepTitle>
				</StepContent>
			</Step>

			<Step disabled={readyToSchedule.disabled} active={readyToSchedule.active} completed={readyToSchedule.completed}>
				<Icon name='phone' />
				<StepContent>
					<StepTitle>Listo para agendar</StepTitle>
				</StepContent>
			</Step>

			<Step disabled={scheduled.disabled} active={scheduled.active} completed={scheduled.completed}>
				<Icon name='checked calendar' />
				<StepContent>
					<StepTitle>Agendado</StepTitle>
				</StepContent>
			</Step>

			{ props.status === "PROYECTO_INCONCLUSO" ? (
				<Step disabled={projectUnfinished.disabled} active={projectUnfinished.active} completed={projectUnfinished.completed}>
					<Icon name='undo' />
					<StepContent>
						<StepTitle>Proyecto inconcluso</StepTitle>
					</StepContent>
				</Step>
			): (
				<Step disabled={projectCompleted.disabled} active={projectCompleted.active} completed={projectCompleted.completed}>
					<Icon name='flag checkered' />
					<StepContent>
						<StepTitle>Proyecto terminado</StepTitle>
					</StepContent>
				</Step>
			)}

		</Step.Group>
    );
};

export default OrderStatusSteps;
