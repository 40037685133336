import React, { SyntheticEvent, useEffect, useState } from "react";
import {
    Button,
    Card,
    Icon,
    Label,
} from "semantic-ui-react";
import LuxonDataTable from "../../components/LuxonDataTable";
import { formatDateTime } from "../../utils/datetimeUtils";
import InputSearch from "../../components/InputSearch";
import PageHeader from "../../components/PageHeader";
import RootTemplate from "../../components/RootTemplate";
import Guarantee from "../../types/entities/Guarantee";
import { toast } from "react-toastify";
import { completeGuarantee, getGuarantees } from "../../services/guarantees.service";
import { formatMoney } from "../../utils/numberUtils";
import GuaranteeDetailModal from "./modals/GuaranteeDetailModal";
import CreateAdjustmentModal from "./modals/CreateAdjustmentModal";
import GuaranteeCompleteModal from "./modals/GuaranteeCompleteModal";

const AdjustmentsList = () => {
    
    const [loading, setLoading] = useState(false);

    const [ perPage, setPerPage ] = useState(10);
    const [ totalRows, setTotalRows ] = useState(0);
    const [ , setCurrentPage ] = useState(1);
    const [ data, setData ] = useState<Guarantee[]>([]);

    const [search, setSearch] = useState("");

    const columns = [
        {
            name: "Fecha",
            selector: (row: Guarantee) => formatDateTime(row.createdAt),
        },
        {
            name: "Cliente",
            selector: (row: Guarantee) => row.client?.person?.fullName ?? "",
        },
        {
            name: "Tipo",
            selector: (row: Guarantee) => <Label color="teal">Ajuste</Label>,
        },
        {
            name: "Saldo pendiente",
            selector: (row: Guarantee) => `$${formatMoney(row.moneyRemaining)}`,
        },
        {
            name: "Acciones",
            button: true,
            style: { justifyContent: "right", paddingRight: "16px", paddingLeft: "16px" },
            cell: (row: Guarantee) => (
                <>
                    <GuaranteeDetailModal guarantee={row} />
                    <Button color="yellow" icon size="mini" href={`/guarantees/adjustments/${row.id}/form`}>
                        <Icon name="pencil" />
                    </Button>
                    <GuaranteeCompleteModal guarantee={row} submit={handleCompleteGuarantee} />
                </>
            )
        },
    ];

	const fetchData = async (page: number, rowsPerPage: number, search: string) => {
        setLoading(true);
        try {
            const response = await getGuarantees(
                rowsPerPage,
                page - 1,
                "createdAt,desc",
                "ADJUSTMENT",
                search
            );
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            toast.error("Error al obtener el listado de garantias");
        } finally {
            setLoading(false);
        }
    };

    // Handlers that call the fetchData method
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        fetchData(page, perPage, search);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
        fetchData(page, newPerPage, search);
    };

    useEffect(() => {
        fetchData(1, perPage, search);
        // eslint-disable-next-line
    }, [search]);

    const handleCompleteGuarantee = async (event: SyntheticEvent, guaranteeId: string | undefined,) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (!guaranteeId) {
                throw new Error("Error al obtener el id de la garantia.");
            }
            const response = await completeGuarantee(guaranteeId);
            if (response?.status === 200) {
                fetchData(1, perPage, search);
                toast.success("La garantia ha sido completada exitosamente.");
            } else {
                throw new Error("Error al completar la garantia.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <RootTemplate>

            <PageHeader 
                title="Ajustes" 
                subtitle="Ajustes a proyectos anteriores"
                actionComponents={
                    <CreateAdjustmentModal />
                }
            />

            <InputSearch 
                icon="search" 
                placeholder="Búsqueda por nombre de cliente..." 
                setSearch={setSearch}
            />

            <Card fluid>
                <LuxonDataTable 
                    columns={columns}
                    data={data}
                    loading={loading}
                    totalRows={totalRows}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                />
            </Card>

        </RootTemplate>
    );
};

export default AdjustmentsList;
