import axios from "axios";
import { API_PRODUCTS_URL } from "../utils/constants";
import { handleServiceError } from "../utils/helpers";

export const getProducts = async (size: number, page: number, sort: string = "createdAt,desc") => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(
            `${API_PRODUCTS_URL}?page=${page}&size=${size}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const createProduct = async (payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(API_PRODUCTS_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const deleteProduct = async (id: string | undefined) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.delete(
            `${API_PRODUCTS_URL}/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};
