import { setHours, setMinutes } from 'date-fns';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Checkbox, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup, Select } from 'semantic-ui-react';
import { getUsers } from '../../../services/users.service';
import { USER_ROLE_ADMINISTRATOR, USER_ROLE_INSTALLER } from '../../../utils/constants';
import DropdownOption from '../../../types/models/DropdownOption';
import { toast } from 'react-toastify';
import Order from '../../../types/entities/Order';
import { updateOrderStatusToScheduled } from '../../../services/orders.service';
import { PAYMENT_METHOD_OPTIONS } from '../../../utils/dropdownOptions';
import { useAppContext } from '../../../hooks/AppProvider';
import { useAuth } from '../../../hooks/AuthProvider';

interface Props {
    id: string | undefined;
    setOrder: (order: Order) => void;
}

const UpdateStatusToScheduledModal = (props: Props) => {

    const { updateParentState } = useAppContext();
    const { userData } = useAuth();
    const isSuperAdmin = userData?.role === USER_ROLE_ADMINISTRATOR;

    const [ openModal, setOpenModal ] = useState(false);
    const [ confirmChecked, setConfirmChecked ] = useState(false);
    const [ sendNotification, setSendNotification ] = useState(true);

    const [ installers, setInstallers ] = useState<DropdownOption[]>([]);

    const [ requestData, setRequestData ] = useState({
        paymentMethod: "",
        installerId: "",
        installationDate: "",
    });
    const [ installationDate, setInstallationDate ] = useState<Date | null>(null);

    const fetchInstallers = useCallback(async () => {
        try {
            const response = await getUsers(100, 0, USER_ROLE_INSTALLER);
            if (!response?.data.content) {
                return;
            }
            const installersList = response?.data.content.map(
                (installer: any) => {
                    return {
                        key: installer.id,
                        value: installer.id,
                        text: installer.person?.fullName ?? "Sin nombre",
                    };
                }
            );
            setInstallers(installersList);
        } catch (error) {
            toast.error("Error al obtener la lista de asesores");
        }
    }, [setInstallers]);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value,
        });
    };

    const handleScheduled = async (event: SyntheticEvent, orderId: string | undefined, sendNotification: boolean) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {

            if (orderId === undefined) {
                throw new Error("El id del pedido no puede ser undefined.");
            }

            if (!installationDate) {
                throw new Error("Favor de seleccionar una fecha de la cita");
            } else {
                requestData.installationDate = installationDate.toISOString();
            }

            const response = await updateOrderStatusToScheduled(orderId, requestData, sendNotification);
            if (response?.status === 200) {
                props.setOrder(response?.data);
                toast.success("El pedido ha sido movido a agendado exitosamente.");
                setOpenModal(false);
            } else {
                throw new Error("Ha ocurrido un error al mover el pedido a produccion.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    useEffect(() => {
        if (openModal) {
            fetchInstallers();
        }
    }, [fetchInstallers, openModal]);

    return (
        <>   
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="green" floated='right' icon onClick={ (event) => handleOpenModal(event) } >
                                Mover pedido a "Agendado"
                            </Button>
                        }
                        content='Mover pedido a "Agendado"'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="check square" content='Mover pedido a "Agendado"' />
                <ModalContent>
                    <p>
                        Recuerda que una vez que modifiques el estatus ya no se podra regresar al estatus
                        anterior y se enviaran las notificaciones correspondientes al cliente.
                    </p>

                    <div className="ui form">

                        <FormField>
                            <label>Fecha y hora de instalación</label>
                            <DatePicker
                                showTimeSelect
                                isClearable
                                minDate={new Date()}
                                minTime={setHours(setMinutes(new Date(),59),7)}
                                maxTime={setHours(setMinutes(new Date(),59),21)}
                                dateFormat="d-MM-yyyy h:mm aa"
                                selected={installationDate}
                                placeholderText="Fecha de instalación"
                                onChange={(date: Date | null) => setInstallationDate(date)}
                                wrapperClassName="date-picker-custom-parent"
                                className="date-picker-custom"
                            />
                        </FormField>

                        <FormField
                            clearable
                            control={Select}
                            label={<label htmlFor="form-select-control-installer">Instalador</label>}
                            options={installers}
                            placeholder="Selecciona una opcion"
                            name="installerId"
                            value={requestData.installerId}
                            onChange={handleChange}
                            required
                            search
                            searchInput={{ id: 'form-select-control-installer' }}
                        />

                        <FormField
                            clearable
                            control={Select}
                            label={<label htmlFor="form-select-control-payment">Metodo de pago</label>}
                            options={PAYMENT_METHOD_OPTIONS}
                            placeholder="Selecciona una opcion"
                            name="paymentMethod"
                            value={requestData.paymentMethod}
                            onChange={handleChange}
                            required
                            search
                            searchInput={{ id: 'form-select-control-payment' }}
                        />

                        <FormField>
                            <Checkbox 
                                toggle
                                disabled={!isSuperAdmin}
                                checked={sendNotification}
                                label='Enviar notificación de whatsapp'
                                onChange={ (event, data) => setSendNotification(sendNotification ? false : true) }
                            />
                        </FormField>

                        <FormField>
                            <Checkbox
                                toggle  
                                label="Confirmo que quiero actualizar el estatus del pedido."
                                checked={confirmChecked}
                                onChange={ (event, data) => setConfirmChecked(confirmChecked ? false : true) }
                            />
                        </FormField>
                    </div>

                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => handleScheduled(event, props.id, sendNotification) } disabled={!confirmChecked}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default UpdateStatusToScheduledModal;
