import React, { SyntheticEvent, useState } from 'react';
import { Button, FormField, Header, Icon, Modal, ModalActions, ModalContent, TextArea } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { createOrderExternalUpdate } from '../../../services/orders.service';
import { useAppContext } from '../../../hooks/AppProvider';
import OrderExternalUpdate from '../../../types/entities/OrderExternalUpdate';

interface Props {
    id: string | undefined;
    disabled?: boolean;
    setOrderExternalUpdate: (externalUpdate: OrderExternalUpdate) => void;
}

const CreateOrderExternalUpdateModal = (props: Props) => {

    const { updateParentState } = useAppContext();
    
    const [ openModal, setOpenModal ] = useState(false);

    const [ requestData, setRequestData ] = useState({
        description: "",
    });

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    const handleChange = (e: any, { name, value }: any) => {
        if (value.length <= 150) {
            setRequestData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (event: SyntheticEvent, orderId: string | undefined) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {

            if (orderId === undefined) {
                throw new Error("El id del pedido no puede ser undefined.");
            }

            const response = await createOrderExternalUpdate(orderId, requestData);
            if (response?.status === 201) {
                props.setOrderExternalUpdate(response?.data);
                toast.success("La actualizacion del proveedor ha sido guardada con exito.");
                setOpenModal(false);
            } else {
                throw new Error("Ha ocurrido un error al registrar la actualizacion del proveedor.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Button size="tiny" color="black" disabled={props.disabled} onClick={ (event) => handleOpenModal(event) }>
                        Nueva actualización del proveedor
                    </Button>    
                } >
                <Header icon="truck" content='Agregar actualizacion del proveedor' />
                <ModalContent>
                    <p>
                        Agrega una descripcion clara y consisa de la actualizacion del proveedor. No sobrepasar mas de 150 caracteres.
                    </p>

                    <div className="ui form">

                        <FormField
                            control={TextArea}
                            label="Actualizacion del proveedor"
                            placeholder="ej. El proveedor ha enviado el producto con el numero de guia 123456."
                            name="description"
                            value={requestData.description}
                            onChange={handleChange}
                        />
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => handleSubmit(event, props.id) }>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default CreateOrderExternalUpdateModal;
