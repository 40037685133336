import User from "../types/entities/User";
import { LOCAL_TOKEN, LOCAL_USER } from "./constants";

export const getLocalToken = () => {
    return localStorage.getItem(LOCAL_TOKEN);
};

export const removeLocalToken = () => {
    localStorage.removeItem(LOCAL_TOKEN);
};

export const setLocalToken = (token: string) => {
    localStorage.setItem(LOCAL_TOKEN, token);
};

export const getLocalUser = () => {
    const user = localStorage.getItem(LOCAL_USER);
    return user ? JSON.parse(user) : null;
};

export const removeLocalUser = () => {
    localStorage.removeItem(LOCAL_USER);
};

export const setLocalUser = (user: User) => {
    localStorage.setItem(LOCAL_USER, JSON.stringify(user));
};

export const handleServiceError = (error: any) => {
    if (error?.response?.status >= 400 && error?.response?.status <= 500) {
        throw new Error(error?.response?.data?.detail);
    } else {
        throw new Error(error?.message ?? "Ha ocurrido un error inesperado.");
    }
};
