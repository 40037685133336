import { USER_ROLE_ADMINISTRATOR, USER_ROLES } from "./constants";

/** Nuevo león cities */
export const NUEVO_LEON_CITIES_OPTIONS = [
    { key: "001", value: "001", text: "Abasolo" },
    { key: "002", value: "002", text: "Agualeguas" },
    { key: "003", value: "003", text: "Los Aldamas" },
    { key: "004", value: "004", text: "Allende" },
    { key: "005", value: "005", text: "Anáhuac" },
    { key: "006", value: "006", text: "Apodaca" },
    { key: "007", value: "007", text: "Aramberri" },
    { key: "008", value: "008", text: "Bustamante" },
    { key: "009", value: "009", text: "Cadereyta Jiménez" },
    { key: "010", value: "010", text: "El Carmen" },
    { key: "011", value: "011", text: "Cerralvo" },
    { key: "012", value: "012", text: "Ciénega de Flores" },
    { key: "013", value: "013", text: "China" },
    { key: "014", value: "014", text: "Doctor Arroyo" },
    { key: "015", value: "015", text: "Doctor Coss" },
    { key: "016", value: "016", text: "Doctor González" },
    { key: "017", value: "017", text: "Galeana" },
    { key: "018", value: "018", text: "García" },
    { key: "019", value: "019", text: "San Pedro Garza García" },
    { key: "020", value: "020", text: "General Bravo" },
    { key: "021", value: "021", text: "General Escobedo" },
    { key: "022", value: "022", text: "General Terán" },
    { key: "023", value: "023", text: "General Treviño" },
    { key: "024", value: "024", text: "General Zaragoza" },
    { key: "025", value: "025", text: "General Zuazua" },
    { key: "026", value: "026", text: "Guadalupe" },
    { key: "027", value: "027", text: "Los Herreras" },
    { key: "028", value: "028", text: "Higueras" },
    { key: "029", value: "029", text: "Hualahuises" },
    { key: "030", value: "030", text: "Iturbide" },
    { key: "031", value: "031", text: "Juárez" },
    { key: "032", value: "032", text: "Lampazos de Naranjo" },
    { key: "033", value: "033", text: "Linares" },
    { key: "034", value: "034", text: "Marín" },
    { key: "035", value: "035", text: "Melchor Ocampo" },
    { key: "036", value: "036", text: "Mier y Noriega" },
    { key: "037", value: "037", text: "Mina" },
    { key: "038", value: "038", text: "Montemorelos" },
    { key: "039", value: "039", text: "Monterrey" },
    { key: "040", value: "040", text: "Parás" },
    { key: "041", value: "041", text: "Pesquería" },
    { key: "042", value: "042", text: "Los Ramones" },
    { key: "043", value: "043", text: "Rayones" },
    { key: "044", value: "044", text: "Sabinas Hidalgo" },
    { key: "045", value: "045", text: "Salinas Victoria" },
    { key: "046", value: "046", text: "San Nicolás de los Garza" },
    { key: "047", value: "047", text: "Hidalgo" },
    { key: "048", value: "048", text: "Santa Catarina" },
    { key: "049", value: "049", text: "Santiago" },
    { key: "050", value: "050", text: "Vallecillo" },
    { key: "051", value: "051", text: "Villaldama" },
];

/** Gender */
export const GENDER_OPTIONS = [
	{ key: 1, text: 'Hombre', value: 'M' },
	{ key: 2, text: 'Mujer', value: 'F' },
	{ key: 3, text: 'Otro', value: 'O' },
];

/** Age ranges */
export const AGE_RANGES_OPTIONS = [
    { key: 1, text: "Menor que 25", value: "<25" },
    { key: 2, text: "26 - 35", value: "26-35" },
    { key: 3, text: "36 - 45", value: "36-45" },
    { key: 4, text: "46 - 55", value: "46-55" },
    { key: 5, text: "Mayor que 55", value: ">55" },
];

/** User roles */
export const USER_ROLES_OPTIONS = Object.entries(USER_ROLES)
    .filter(([, v]) => v.key !== USER_ROLE_ADMINISTRATOR)
    .map(([, v]) => ({ key: v.key, text: v.label, value: v.key }));

/** User colors */
export const USER_COLOR_OPTIONS = [
    { key: '1', text: 'Lavender', value: '1', icon: { name: 'circle', className: 'google-lavander' } },
    { key: '2', text: 'Sage', value: '2', icon: { name: 'circle', className: 'google-sage' } },
    { key: '3', text: 'Grape', value: '3', icon: { name: 'circle', className: 'google-grape' } },
    { key: '4', text: 'Flamingo', value: '4', icon: { name: 'circle', className: 'google-flamingo' } },
    { key: '5', text: 'Banana', value: '5', icon: { name: 'circle', className: 'google-banana' } },
    { key: '6', text: 'Tangerine', value: '6', icon: { name: 'circle', className: 'google-tengerine' } },
    { key: '7', text: 'Peacock', value: '7', icon: { name: 'circle', className: 'google-peacock' } },
    { key: '8', text: 'Graphite', value: '8', icon: { name: 'circle', className: 'google-graphite' } },
    { key: '9', text: 'Blueberry', value: '9', icon: { name: 'circle', className: 'google-blueberry' } },
    { key: '10', text: 'Basil', value: '10', icon: { name: 'circle', className: 'google-basil' } },
    { key: '11', text: 'Tomato', value: '11', icon: { name: 'circle', className: 'google-tomato' } },
];

/** Payment methods */
export const PAYMENT_METHOD_OPTIONS = [
    { key: "EFECTIVO", text: "Efectivo", value: "EFECTIVO" },
    { key: "TRANSFERENCIA", text: "Transferencia", value: "TRANSFERENCIA" },
    { key: "TDC_TDD", text: "Tarjeta de credito/debito", value: "TDC_TDD" },
];

/** Prospect origins */
export const PROSPECT_ORIGIN_OPTIONS = [
	{ key: 'FACEBOOK', text: 'Facebook', value: 'FACEBOOK' },
	{ key: 'INSTAGRAM', text: 'Instagram', value: 'INSTAGRAM' },
	{ key: 'WHATSAPP', text: 'WhatsApp', value: 'WHATSAPP' },
    { key: 'WEB_PAGE', text: 'Página Web', value: 'WEB_PAGE' },
    { key: 'PHONE_CALL', text: 'Llamada', value: 'PHONE_CALL' },
    { key: 'EMAIL', text: 'Correo Electrónico', value: 'EMAIL' },
    { key: 'EXISTING_CUSTOMER', text: 'Cliente Antiguo', value: 'EXISTING_CUSTOMER' },
];

/** Prospect origins */
export const PAYMENT_TYPES_OPTIONS = [
	{ key: 'ADVANCE', text: 'Anticipo', value: 'ADVANCE' },
	{ key: 'INSTALLMENT', text: 'Abono', value: 'INSTALLMENT' },
	{ key: 'PARTIAL_SETTLEMENT', text: 'Liquidacion parcial', value: 'PARTIAL_SETTLEMENT' },
    { key: 'FULL_SETTLEMENT', text: 'Liquidacion total', value: 'FULL_SETTLEMENT' },
];

