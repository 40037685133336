import React, { SyntheticEvent, useState } from "react";
import Guarantee from "../../../types/entities/Guarantee";
import { Button, Header, Icon, Modal, ModalActions, ModalContent, Popup } from "semantic-ui-react";

interface Props {
    guarantee: Guarantee,
    submit: (event: SyntheticEvent, guaranteeId: string) => void;
};

const GuaranteeCompleteModal = (props: Props) => {

    const [ openModal, setOpenModal ] = useState(false);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="tiny"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="green" icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="check" />
                            </Button>
                        }
                        content='Completar garantia'
                        position='bottom center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="list" content="Detalle de la garantía" />
                <ModalContent>
                    <p>
                        Una vez completada la garantia, dejara de aparecer en este listado. Si estas deacuerdo da click en Completar.
                    </p>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cerrar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.guarantee.id) }>
                        <Icon name="checkmark" /> Completar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default GuaranteeCompleteModal;