import React, { useEffect, useState } from "react";
import { Accordion, AccordionContent, AccordionTitle, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";

import Order from "../../../types/entities/Order";
import { formatDateTime } from "../../../utils/datetimeUtils";
import OrderExternalUpdate from "../../../types/entities/OrderExternalUpdate";
import { getOrderExternalUpdates } from "../../../services/orders.service";
import { toast } from "react-toastify";
import CreateOrderExternalUpdateModal from "../modals/CreateOrderExternalUpdateModal";
import { useAuth } from "../../../hooks/AuthProvider";
import { USER_ROLE_ADMINISTRATOR, USER_ROLE_ORDERS } from "../../../utils/constants";

interface Props {
    orderId: string | undefined;
    order: Order | undefined;
    setOrder: (order: Order | undefined) => void;
}

const ExternalUpdatesAccordion = ({ orderId, order, setOrder }: Props) => {

    const [ loading, setLoading ] = useState(false);
    const [ activeIndex, setActiveIndex ] = useState(-1);
    const [ data, setData ] = useState<OrderExternalUpdate[]>([]);

    const { userData } = useAuth();
    const canCreateNewUpdate = userData?.role === USER_ROLE_ORDERS || userData?.role === USER_ROLE_ADMINISTRATOR;

    const fetchData = async (orderId: string) => {
        setLoading(true);
        try {
            const response = await getOrderExternalUpdates(orderId);
            setData(response?.data);
        } catch (error) {
            toast.error("Error al obtener el listado de actualizaciones externas.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!orderId || activeIndex !== 0) {
            return;
        }
        fetchData(orderId);
    }, [activeIndex, orderId]);

    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const setOrderExternalUpdate = (orderExternalUpdate: OrderExternalUpdate) => {
        data.push(orderExternalUpdate);
        setData(data);
    }

    return (
        <Accordion fluid styled style={{ marginBottom: "1.2em" }}>
            <AccordionTitle 
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}>
                <Icon name="dropdown" /> Actualizaciones externas (proveedores)
            </AccordionTitle>
            <AccordionContent active={activeIndex === 0}>
                <CreateOrderExternalUpdateModal id={orderId} disabled={loading || !canCreateNewUpdate} setOrderExternalUpdate={setOrderExternalUpdate} />
                <Table striped style={{ marginTop: "1.2em" }}>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Actualizaciones</TableHeaderCell>
                            <TableHeaderCell>Fecha</TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        { data?.map((update: OrderExternalUpdate) => (
                            <TableRow key={update?.id}>
                                <TableCell>{ update?.description }</TableCell>
                                <TableCell>
                                    { formatDateTime(update?.createdAt) }
                                </TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </AccordionContent>
        </Accordion>
    );
};

export default ExternalUpdatesAccordion;
