import React, { SyntheticEvent } from 'react';
import { Button, Header, Icon, Modal, ModalActions, ModalContent, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { formatDateTime } from '../../../utils/datetimeUtils';
import Order from '../../../types/entities/Order';

interface Props {
    order: Order | undefined;
    openModal: boolean;
    setOpenModal: (open: boolean) => void;
}

const TracingOrderListModal = ({ order, openModal, setOpenModal }: Props) => {

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                onClose={(event) => handleCancelModal(event)}
                open={openModal} >
                <Header icon="truck" content="Seguimiento del pedido" />
                <ModalContent>
                    <Table striped>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Movimiento</TableHeaderCell>
                                <TableHeaderCell>Fecha</TableHeaderCell>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            <TableRow>
                                <TableCell>Creación del pedido</TableCell>
                                <TableCell>{ formatDateTime(order?.orderDate) }</TableCell>
                            </TableRow>
                            { order?.productionStartDate && (<TableRow>
                                <TableCell>Inicio de producción</TableCell>
                                <TableCell>{ formatDateTime(order?.productionStartDate) }</TableCell>
                            </TableRow>) }
                            { order?.storeReceivedDate && (<TableRow>
                                <TableCell>Recibido en almacen</TableCell>
                                <TableCell>{ formatDateTime(order?.storeReceivedDate) }</TableCell>
                            </TableRow>) }
                            { order?.productionEndDate && (<TableRow>
                                <TableCell>Listo para agendar</TableCell>
                                <TableCell>{ formatDateTime(order?.productionEndDate) }</TableCell>
                            </TableRow>) }
                            { order?.installationDate && (<TableRow positive>
                                <TableCell>Instalación agendada</TableCell>
                                <TableCell>{ formatDateTime(new Date(order?.installationDate)) } </TableCell>
                            </TableRow>) }
                        </TableBody>
                    </Table>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) } >
                        <Icon name="cancel" /> Cancelar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default TracingOrderListModal;
