import OrderStatus from "../types/models/OrderStatus";

// env constants
export const API_URL = process.env.REACT_APP_API_URL;

// utils constants
export const LOCAL_TOKEN = "access_token";
export const LOCAL_USER = "user";

// url constants (endpoints)
export const API_AUTH_URL = API_URL + "auth";
export const API_AUTH_LOGIN_URL = API_AUTH_URL + "/login";
export const API_ORDERS_URL = API_URL + "orders";
export const API_ORDERS_BY_INVOICE_URL = API_ORDERS_URL + "/invoice";
export const API_ORDERS_REQUESTS_URL = API_ORDERS_URL + "/requests";
export const API_ORDERS_ORDERS_URL = API_ORDERS_URL + "/orders";
export const API_ORDERS_REMINDERS_URL = API_ORDERS_URL + "/reminders";
export const API_ADVISORS_URL = API_URL + "advisors";
export const API_PRODUCTS_URL = API_URL + "products";
export const API_INSTALLERS_URL = API_URL + "installers";
export const API_PROSPECTS_URL = API_URL + "prospects";
export const API_USERS_URL = API_URL + "users";
export const API_CLIENTS_URL = API_URL + "clients";
export const API_GUARANTEES_URL = API_URL + "guarantees";

// utils
export const ORDER_STATUS_VALIDANDO_PEDIDO = "VALIDANDO_PEDIDO";
export const ORDER_STATUS_EN_PRODUCCION = "EN_PRODUCCION";
export const ORDER_STATUS_RECIBIDO_EN_ALMACEN = "RECIBIDO_EN_ALMACEN";
export const ORDER_STATUS_LISTO_PARA_AGENDAR = "LISTO_PARA_AGENDAR";
export const ORDER_STATUS_AGENDADO = "AGENDADO";
export const ORDER_STATUS_PROYECTO_TERMINADO = "PROYECTO_TERMINADO";
export const ORDER_STATUS_PROYECTO_INCONCLUSO = "PROYECTO_INCONCLUSO";
export const ORDER_STATUS_CANCELADO = "CANCELADO";

export const TRACING_STATUSES: OrderStatus = {
    1: {
		name: "Seguimiento #1",
		color: "green"
	},
	2: {
		name: "Seguimiento #2",
		color: "olive"
	},
	3: {
		name: "Seguimiento #3",
		color: "yellow"
	},
	4: {
		name: "Seguimiento #4",
		color: "orange"
	},
	5: {
		name: "Seguimiento #5",
		color: "red"
	},
};

export const ORDERS_STATUSES: OrderStatus = {
    VALIDANDO_PEDIDO: {
		name: "Validando pedido",
		color: "yellow"
	},
	EN_PRODUCCION: {
		name: "En produccion",
		color: "grey"
	},
	RECIBIDO_EN_ALMACEN: {
		name: "Recibido en almacen",
		color: "olive"
	},
	LISTO_PARA_AGENDAR: {
		name: "Listo para agendar",
		color: "green"
	},
	AGENDADO: {
		name: "Agendado",
		color: "orange"
	},
	PROYECTO_TERMINADO: {
		name: "Proyecto terminado",
		color: "blue"
	},
	CANCELADO: {
		name: "Cancelado",
		color: "red"
	},
    PROYECTO_INCONCLUSO: {
		name: "Proyecto inconcluso",
		color: "brown"
	},
};

export const PROSPECTS_STATUSES: OrderStatus = {
    ASSIGNED: {
		name: "Asignado",
		color: "yellow"
	},
	IN_TRACING: {
		name: "En seguimiento",
		color: "blue"
	},
	SALE_COMPLETED: {
		name: "Venta concretada",
		color: "green"
	},
	SALE_NOT_COMPLETED: {
		name: "Venta no concretada",
		color: "red"
	},
};

// datatables
export const DT_CUSTOM_STYLES = {
    head: {
        style: {
            fontSize: "14px",
            fontWeight: 800,
        },
    },
};

export const DT_PAGINATION_COMPONENT_OPTIONS = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
};

// user roles
export const USER_ROLE_ADMINISTRATOR = "ADMINISTRATOR";
export const USER_ROLE_LOGISTIC = "LOGISTIC";
export const USER_ROLE_ORDERS = "ORDERS";
export const USER_ROLE_SOCIAL_MEDIA = "SOCIAL_MEDIA";
export const USER_ROLE_ADVISOR = "ADVISOR";
export const USER_ROLE_INSTALLER = "INSTALLER";

// user role labels
export const USER_ROLES = {
	ADMIN: {
        key: USER_ROLE_ADMINISTRATOR,
        label: "Administrador",
    },
    LOGISTIC: {
        key: USER_ROLE_LOGISTIC,
        label: "Logistica",
    },
    ORDERS: {
        key: USER_ROLE_ORDERS,
        label: "Pedidos",
    },
    SOCIAL_MEDIA: {
        key: USER_ROLE_SOCIAL_MEDIA,
        label: "Redes sociales",
    },
    ADVISOR: {
        key: USER_ROLE_ADVISOR,
        label: "Asesor",
    },  
    INSTALLER: {
        key: USER_ROLE_INSTALLER,
        label: "Instalador",
    },  
};
