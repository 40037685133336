import axios from "axios";
import { API_GUARANTEES_URL } from "../utils/constants";
import { handleServiceError } from "../utils/helpers";

export const createGuaranteeAdjustment = async (payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_GUARANTEES_URL}/adjustment`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const getGuarantees = async (
    size: number = 10,
    page: number = 0,
    sort: string = "createdAt,asc",
    type?: string,
    search?: string,
) => {
    const token = localStorage.getItem("access_token");
    try {
        const typeParam = type ? `&type=${type}` : "";
        const searchParam = search ? `&searchByFullName=${search}` : "";
        const response = await axios.get(`${API_GUARANTEES_URL}?page=${page}&size=${size}&sort=${sort}${typeParam}${searchParam}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const getGuaranteeById = async (
    guaranteeId: string
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(`${API_GUARANTEES_URL}/${guaranteeId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const getGuaranteeFilesByGuaranteeId = async (
    guaranteeId: string
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(`${API_GUARANTEES_URL}/${guaranteeId}/files`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const uploadGuaranteePaymentProof = async (
    guaranteeId: string,
    formData: FormData
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_GUARANTEES_URL}/${guaranteeId}/uploadFiles`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            }
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const uploadGuaranteeFile = async (
    guaranteeId: string,
    formData: FormData
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_GUARANTEES_URL}/${guaranteeId}/files/uploadFiles`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            }
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const createGuaranteeClientPerson = async (id: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_GUARANTEES_URL}/${id}/client/person`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateGuaranteeClientPerson = async (id: string, personId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_GUARANTEES_URL}/${id}/client/person/${personId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const createGuaranteeAddress = async (id: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_GUARANTEES_URL}/${id}/address`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const updateGuaranteeAddress = async (id: string, addressId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_GUARANTEES_URL}/${id}/address/${addressId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const assignClientToGuarantee = async (id: string, clientId: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_GUARANTEES_URL}/${id}/assign-client/${clientId}`;
        const response = await axios.put(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};

export const completeGuarantee = async (id: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_GUARANTEES_URL}/${id}/complete`;
        const response = await axios.put(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error: any) {
        handleServiceError(error);
    }
};
