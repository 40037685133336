import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup, Select } from 'semantic-ui-react';
import { getProducts } from '../../../services/products.service';
import DropdownOption from '../../../types/models/DropdownOption';
import Prospect from '../../../types/entities/Prospect';

interface RequestData {
    productId?: string;
}

interface Props {
    prospect: Prospect | undefined;
    submit: (event: SyntheticEvent, prospectId: string | undefined, saleStatus: string, productId?: string) => void;
}

const SaleCompletedModal = (props: Props) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ saleCompleted, setSaleCompleted ] = useState(false);

    const [ products, setProducts ] = useState<DropdownOption[]>([]);

    const [ requestData, setRequestData ] = useState<RequestData>({
        productId: props.prospect?.product?.id,
    });

    const fetchProducts = async () => {
        try {
            const response = await getProducts(100, 0);
            const productsList = response?.data.content.map(
                (product: any) => {
                    return {
                        key: product["id"],
                        value: product["id"],
                        text: `${product["sku"]} - ${product["name"]}`,
                    };
                }
            );
            setProducts(productsList);
        } catch (error) {
            console.error("Error al obtener la lista de asesores:", error);
        }
    };

    useEffect(() => {
        if (openModal) {
            fetchProducts();
            requestData.productId = props.prospect?.product?.id;
        }
        // eslint-disable-next-line
    }, [openModal]);

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        } as RequestData);
    };

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="green" icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="check" />
                            </Button>
                        }
                        content='Venta concretada'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="check" content="Venta concretada" />
                <ModalContent>
                    
                    <p>¿Estas seguro que la venta ha sido concretada y el producto seleccionado es el correcto? Una vez que hayas realizado este paso, no podras volver atras.</p>

                    <div className="ui form">
                        <FormField
                            control={Select}
                            label={<label htmlFor="form-select-control-advisor">Producto</label>}
                            options={products}
                            placeholder="Selecciona una opcion"
                            name="productId"
                            value={requestData.productId}
                            onChange={handleChange}
                            required
                            search
                            searchInput={{id: "form-select-control-advisor"}}
                            clearable
                        />

                        <FormField>
                            <Checkbox
                                label="Confirmo que la venta ha sido concretada."
                                checked={saleCompleted}
                                onChange={ (event, data) => setSaleCompleted(saleCompleted ? false : true) }
                            />
                        </FormField>
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.prospect?.id, "SALE_COMPLETED", requestData.productId) } disabled={!saleCompleted}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default SaleCompletedModal;
